import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation } from '@wix/yoshi-flow-editor';
import { focus, getMainVideoId } from '@wix/wix-vod-shared/widget';
import {
  getChannelForWidget,
  getChannelCoverUrl,
  memoizedPartial,
} from '@wix/wix-vod-shared/common';
import { notForPreview } from '../../utils/not-for-preview';
import {
  isClassicLayoutSearchBarVisible,
  isSearchInputVisible,
  isCategoriesDropdownVisible,
  isClassicLayoutHeaderVisible,
} from '../../selectors/visibility';
import { isMobile } from '../../selectors/form-factor';
import { storeForReload } from '../../utils/reload';
import { openFullScreenChannelOverlay } from '../../utils/open-overlay-base';
import {
  openFullScreenMemberOnlyOverlay,
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenRentOverlay,
} from '../../utils/open-overlay';
import {
  playerSize,
  listThumbnailSize,
} from '../../utils/videos-sizes/videos-sizes';
import { selectVideo } from '../../redux/actions/select-video';
import { getCurrentSiteUser } from '../../selectors/current-site-user';
import {
  searchByString,
  searchByCategory,
  searchByTag,
} from '../../redux/actions/search';
import { goToLazyVideosPageIndex } from '../../redux/lazy-channel-videos/actions';
import { getMainVideo } from '../../selectors/get-video';
import { showAutoPlay } from '../../selectors/layout';
import {
  getAllSettings,
  getNumberOfRows,
  isMainItemChannelCover,
  getCanShowSignIn,
} from '../../selectors/app-settings';
import { getVideosGroupedByIds } from '../../selectors/videos';
import {
  isVideoPlayingOptimistic,
  isVideoPausedOptimistic,
  isVideoEnded,
} from '../../selectors/video-playback-status';
import { getIsSearching, getSearchQuery } from '../../selectors/search';
import { logBi, logWidgetSystem } from '../../worker/actions/bi';
import { isSubscriptionButtonVisible } from '../../components/overlay-texts/channel/subscribe-button-text-utils';
import { logWidgetVidClick } from '../../utils/bi/widget-common-events';
import { sendBiEvent } from '../../bi/send-bi-event';
import PlayerBlocksVisibility from '../../containers/player-blocks-visibility';
import { requestPlayVideo } from '../../redux/actions/request-play-video';
import ShareOverlay from '../../containers/share-overlay/share-overlay';
import OpenShareOverlay from '../../containers/open-share-overlay/open-share-overlay';
import ShareButton from '../../components/share-button/share-button';
import AccountButton from '../../components/account/account-button/account-button';
import ChannelInfoButton from '../../components/channel-actions/channel-info-button';
import ChannelSubscriptionButton from '../../components/channel-actions/channel-subscription-button';
import Categories from '../../components/categories/categories';
import Tags from '../../containers/tags/tags';
import SearchBar from '../../components/search-bar/search-bar';
import Videos from './components/videos/videos';
import ClassicPlayerOverlay from '../../components/player-overlay/classic/classic';
import { getCurrentChannelId } from '../../selectors/channels';
import { WidgetScrollbarWrapper } from '../../components/scrollbar-wrapper/scrollbar-wrapper';
import EmptySearchResults from '../../components/empty-search-results/empty-search-results';
import DeeplinkPopups from '../../components/deeplink-popups/deeplink-popups';
import AccessibleVideosContainer from '../../components/accessible-videos-container/accessible-videos-container';
import PaymentEvents from '../../components/payment-events/payment-events';
import LiveStartHandler from '../../components/live-start-handler/live-start-handler';
import { setSavedTime } from '../../redux/actions/player/set-saved-time';
import {
  getVideosPerPageCount,
  getVideoIdsByPageNumber,
  getVideoIds,
  getCurrentPageIndex,
  getIsFetching,
  getCurrentVideoIndex,
  hasNextVideo,
} from '../../redux/lazy-channel-videos/selectors';
import { getIsSearchResultEmpty } from '../../selectors/search-results';
import { AutoPlayVideo } from '../../components/autoplay-video/autoplay-video';
import { WidgetPerformanceLoggers } from '../../containers/performance-loggers/performance-loggers';
import ChannelActionsContainer from '../../containers/channel-actions/channel-actions';
import { withPlayerModuleLoader } from '../../data-components/player-module-loader';
import styles from './classic.scss';
import * as viewModeSelectors from '../../selectors/view-mode';
import { fitIntoView } from '../../worker/actions/fit-into-view';
import { ComingSoon } from '../../components/no-videos/coming-soon';
import { getInstance } from '../../redux/hydrated-data/hydrated-data';
import {
  endPlayInteraction,
  startPlayInteraction,
  withFedopsInteractions,
} from '../../fedops/interactions';
import { withWindowSize } from '../../containers/window-size';
import isIOS from '../../utils/isIOS';

const DEFAULT_STATE = {
  isTagFocused: false,
  isSearchInputFocused: false,
  searchLayout: false,
  currentCategory: null,
};

const mapStateToProps = (state, props) => {
  const lazyLoadedVideoIds = getVideoIds(state);
  const currentIdIndex = getCurrentVideoIndex(state);
  const nextVideoId = hasNextVideo(state)
    ? lazyLoadedVideoIds[currentIdIndex + 1]
    : null;

  return {
    isSite: viewModeSelectors.isSiteMode(state),
    isEditor: viewModeSelectors.isEditorMode(state),
    mainVideoId: getMainVideoId(state),
    mainVideo: getMainVideo(state),
    nextVideoId,
    selectedVideoId: state.selectedVideoId,
    isVideoPlaying: isVideoPlayingOptimistic(state),
    isVideoPaused: isVideoPausedOptimistic(state),
    isVideoEnded: isVideoEnded(state),
    currentChannelId: getCurrentChannelId(state),
    canShowChannelCover: !state.isVideosTouched,
    appSettings: getAllSettings(state),
    currentSiteUser: getCurrentSiteUser(state),
    firstChannelVideoId: state.firstChannelVideoId,
    numberOfRows: getNumberOfRows(state),
    videoByIds: getVideosGroupedByIds(state),
    isSignInVisible: getCanShowSignIn(state),
    isSearchBarVisible: isClassicLayoutSearchBarVisible(state),
    isSearchInputVisible: isSearchInputVisible(state),
    isCategoriesDropdownVisible: isCategoriesDropdownVisible(state),
    isHeaderVisible: isClassicLayoutHeaderVisible(state),
    searchQuery: getSearchQuery(state),
    channelData: getChannelForWidget(state),
    videosPerPage: getVideosPerPageCount(state),
    videoIdsByPageNumber: getVideoIdsByPageNumber(state),
    currentVideosPageNumber: getCurrentPageIndex(state),
    isVideosFetching: getIsFetching(state),
    isSearching: getIsSearching(state),
    isSearchResultEmpty: getIsSearchResultEmpty(state),
    isMainItemChannelCover: isMainItemChannelCover(state),
    showAutoPlay: showAutoPlay(state),
    playerSize: playerSize(props.windowSize.width),
    instance: getInstance(state),
    isMobile: isMobile(state),
  };
};

const mapDispatchToProps = {
  selectVideo,
  searchByString,
  searchByCategory,
  searchByTag,
  setSavedTime,
  goToLazyVideosPageIndex,
  requestPlayVideo,
  logBi,
  logWidgetSystem,
  logWidgetVidClick,
  storeForReload,
  sendBiEvent,
  openFullScreenMemberOnlyOverlay,
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenRentOverlay,
  openFullScreenChannelOverlay,
  fitIntoView,
};

const ClassicViewComponent = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class ClassicView extends React.Component {
      static displayName = 'ClassicView';

      static propTypes = {
        isSite: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        currentSiteUser: PropTypes.object,
        currentChannelId: PropTypes.string,
        mainVideoId: PropTypes.string,
        mainVideo: PropTypes.object,
        selectVideo: PropTypes.func.isRequired,
        canShowChannelCover: PropTypes.bool.isRequired,
        nextVideoId: PropTypes.string,
        channelData: PropTypes.object.isRequired,
        videoByIds: PropTypes.object.isRequired,
        appSettings: PropTypes.object.isRequired,
        windowSize: PropTypes.object.isRequired,
        isVideoEnded: PropTypes.bool,
        isSearchBarVisible: PropTypes.bool,
        isSearchInputVisible: PropTypes.bool,
        isCategoriesDropdownVisible: PropTypes.bool,
        isHeaderVisible: PropTypes.bool.isRequired,
        isSignInVisible: PropTypes.bool,
        isSearching: PropTypes.bool,
        videosPerPage: PropTypes.number,
        searchQuery: PropTypes.string,
        numberOfRows: PropTypes.number,
        setSavedTime: PropTypes.func,
        goToLazyVideosPageIndex: PropTypes.func.isRequired,
        currentVideosPageNumber: PropTypes.number.isRequired,
        videoIdsByPageNumber: PropTypes.array.isRequired,
        isVideosFetching: PropTypes.bool.isRequired,
        searchByString: PropTypes.func.isRequired,
        searchByTag: PropTypes.func.isRequired,
        searchByCategory: PropTypes.func.isRequired,
        isSearchResultEmpty: PropTypes.bool.isRequired,
        isMainItemChannelCover: PropTypes.bool.isRequired,
        requestPlayVideo: PropTypes.func.isRequired,
        fitIntoView: PropTypes.func.isRequired,
        selectedVideoId: PropTypes.string,
        isVideoPlaying: PropTypes.bool.isRequired,
        showAutoPlay: PropTypes.bool,

        PlayerComponent: PropTypes.func,
        playerSize: PropTypes.shape({
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
        }).isRequired,
      };

      constructor(props) {
        super(props);

        this.state = {
          ...DEFAULT_STATE,
          searchBarValue: props.searchQuery,
          isInitialVideosLoaded: false,
        };
      }

      componentDidMount() {
        this.sendSubscriptionDisplayedOnWidget();
        this.props.goToLazyVideosPageIndex(0);
      }

      componentDidUpdate(prevProps) {
        const isSelectedVideoChanged =
          prevProps.selectedVideoId !== this.props.selectedVideoId;

        if (isSelectedVideoChanged) {
          focus(this.playerOverlayContainerRef);
        }
      }

      sendSubscriptionDisplayedOnWidget() {
        const { channelData } = this.props;
        if (isSubscriptionButtonVisible({ channel: channelData })) {
          this.props.sendBiEvent('widget.subscription.displayed', {
            whereDisplayed: 'widget',
          });
        }
      }

      playerOverlayContainerRef = null;

      saveRef = (name, ref) => {
        this[name] = ref;
      };

      get listThumbnailSize() {
        const { appSettings, windowSize } = this.props;
        const videosInRow = appSettings.numbers.videosInRow;
        return listThumbnailSize(windowSize.width, videosInRow);
      }

      reset = () => {
        this.setState({
          ...DEFAULT_STATE,
          searchBarValue: '',
        });

        this.props.goToLazyVideosPageIndex(0);
      };

      handleThumbnailClick = (videoItem) => {
        const { selectedVideoId, fitIntoView, selectVideo } = this.props;

        if (selectedVideoId === videoItem.id) {
          return;
        }

        fitIntoView();

        this.containerRef.scrollIntoView({ behavior: 'smooth' });

        selectVideo(videoItem.id);
      };

      getTagList() {
        return this.props.channelData?.statsInfo?.tags || [];
      }

      get categoriesList() {
        const categoriesStats =
          _.get(this.props, 'channelData.statsInfo.categories') || [];
        return _.map(categoriesStats, 'value');
      }

      searchByTag = (tag) => {
        this.props.logWidgetSystem('videoList.searchByTag.requested');
        this.setTagsFocusState(false);
        this.props.searchByTag(tag);
        this.setState({
          searchBarValue: tag,
          currentCategory: null,
        });

        this.props.logBi('widget.tags.clicked');
      };

      handleTagsFocus = () => {
        this.setTagsFocusState(true);
      };

      handleTagsBlur = () => {
        this.setTagsFocusState(false);
      };

      setTagsFocusState(isFocused) {
        this.setState({
          isTagFocused: isFocused,
        });

        if (!isFocused) {
          setTimeout(() => this.hideSearchLayout(), 0);
        }
      }

      get tags() {
        const { searchLayout, searchBarValue } = this.state;
        const tags = this.getTagList();

        if (!tags.length || !searchLayout || searchBarValue) {
          return null;
        }

        return (
          <div className={styles['tags-wrapper']} data-hook="tags-wrapper">
            <WidgetScrollbarWrapper
              style={{ maxHeight: this.listThumbnailSize?.height }}
            >
              <Tags
                className={styles.tags}
                onTagFocus={this.handleTagsFocus}
                onTagBlur={this.handleTagsBlur}
                onTagClick={this.searchByTag}
                tags={tags}
              />
            </WidgetScrollbarWrapper>
          </div>
        );
      }

      get noSearchResults() {
        const { isSearchResultEmpty } = this.props;
        const { searchBarValue, searchLayout } = this.state;

        if (!isSearchResultEmpty || (searchLayout && !searchBarValue)) {
          return null;
        }

        return <EmptySearchResults className={styles['no-search-results']} />;
      }

      handleSlideToPrev = () => {
        this.props.logWidgetSystem('videoList.changePage.requested');
        const { currentVideosPageNumber, goToLazyVideosPageIndex } = this.props;
        goToLazyVideosPageIndex(currentVideosPageNumber - 1);
      };

      handleSlideToNext = () => {
        this.props.logWidgetSystem('videoList.changePage.requested');
        const { currentVideosPageNumber, goToLazyVideosPageIndex } = this.props;
        goToLazyVideosPageIndex(currentVideosPageNumber + 1);
      };

      isChannelCoverShown() {
        return (
          this.props.canShowChannelCover && this.props.isMainItemChannelCover
        );
      }

      handleThumbnailPlayButtonClick = (videoItem) => {
        const { channelData, mainVideo } = this.props;

        // We play muted video on IOS, if it's not selected and its iframe is not rendered, because of restrictions of playing unmuted content.
        // Selected video has an iframe rendered in player, and it can be played unmuted.
        if (isIOS()) {
          const muted = mainVideo.id !== videoItem.id;
          this.playVideo(videoItem, muted);
        } else {
          this.playVideo(videoItem);
        }

        logWidgetVidClick({ videoItem, channelData });
      };

      get videosList() {
        const {
          channelData,
          currentSiteUser,
          videoIdsByPageNumber,
          currentVideosPageNumber,
          videosPerPage,
          mainVideo,
          playerSize,
        } = this.props;

        const { searchLayout, searchBarValue } = this.state;

        const searchBlockShown =
          searchLayout && !searchBarValue && this.getTagList().length;
        const noVideosLoaded = !_.get(
          videoIdsByPageNumber[currentVideosPageNumber],
          'length',
        );

        // TODO: this is crap, need to show videoList OR noSearchResults on upper level
        if (noVideosLoaded || !mainVideo || searchBlockShown) {
          return null;
        }

        return (
          <Videos
            className={styles.videos}
            containerWidth={playerSize.width}
            videoIdsByPageNumber={videoIdsByPageNumber}
            currentVideosPageNumber={currentVideosPageNumber}
            onSlideToNext={this.handleSlideToNext}
            onSlideToPrev={this.handleSlideToPrev}
            channelData={channelData}
            currentSiteUser={currentSiteUser}
            videosPerPageCount={videosPerPage}
            onThumbnailClick={this.handleThumbnailClick}
            onPlayRequest={this.handleThumbnailPlayButtonClick}
            onPlayMemberOnlyRequest={this.openMemberOnly}
            thumbnailSize={this.listThumbnailSize}
            isSelectedVideoIndicationDisabled={this.isChannelCoverShown()}
          />
        );
      }

      handleSearchInputFocus = () => {
        this.setSearchInputFocusState(true);
      };

      handleSearchInputBlur = () => {
        // In safari when we click the tag, it triggers blur faster and hides the buttons
        // so the tag button's click event doesn't trigger and therefore nothing works.
        // previous attempt was to do it in requestAnimationFrame, but this also doesn't work
        // by default buttons do not get focus in safari
        // and therefore the focus-within approach for the whole section doesn't work either
        setTimeout(() => {
          this.setSearchInputFocusState(false);
        }, 300);
      };

      setSearchInputFocusState(isFocused) {
        this.setState({
          isSearchInputFocused: isFocused,
        });

        if (isFocused) {
          this.showSearchLayout();
        } else {
          setTimeout(() => this.hideSearchLayout(), 0);
        }
      }

      showSearchLayout() {
        this.setState({ searchLayout: true });
      }

      hideSearchLayout() {
        const { isTagFocused, isSearchInputFocused } = this.state;
        if (!isTagFocused && !isSearchInputFocused) {
          this.setState({
            searchLayout: false,
          });
        }
      }

      clearSearch = () => {
        this.setState({ searchBarValue: '' });

        if (!this.state.currentCategory) {
          // if category is selected nothing to reset
          this.props.searchByString('');
        }
      };

      searchByQuery = (value) => {
        this.props.searchByString(value);
        this.props.logWidgetSystem('videoList.searchByQuery.requested');
        this.setState({ currentCategory: null });
      };

      handleSearchInputChange = (searchBarValue) => {
        this.setState({ searchBarValue });
      };

      get search() {
        const { isSearchInputVisible, searchQuery } = this.props;
        const { currentCategory } = this.state;

        if (!isSearchInputVisible) {
          return null;
        }

        const value = currentCategory ? '' : searchQuery;

        return (
          <SearchBar
            value={value}
            onFocus={this.handleSearchInputFocus}
            onBlur={this.handleSearchInputBlur}
            onChange={this.handleSearchInputChange}
            onSearch={this.searchByQuery}
            onClose={this.clearSearch}
            className={styles.search}
          />
        );
      }

      handleCategorySelect = (category) => {
        this.props.searchByCategory(category);
        this.props.logWidgetSystem('videoList.searchByCategory.requested');
        this.setState({
          currentCategory: category,
          searchBarValue: '',
        });
      };

      get categories() {
        const {
          isCategoriesDropdownVisible,
          searchQuery,
          videosPerPage,
          videoByIds,
        } = this.props;
        const { searchLayout, currentCategory, searchBarValue } = this.state;

        if (
          !isCategoriesDropdownVisible ||
          (!currentCategory && (searchBarValue || searchQuery))
        ) {
          return null;
        }

        const className = classnames(styles['categories-container'], {
          [styles.hidden]: searchLayout,
        });

        return (
          // wrapper needed for focusing when switching from search bar to categories by tab
          <div className={className}>
            <Categories
              className={styles.categories}
              maxOptionsVisible={_.size(videoByIds) > videosPerPage ? 5 : 3}
              isFocusable={!searchQuery || Boolean(currentCategory)}
              list={this.categoriesList}
              onCategorySelect={this.handleCategorySelect}
              selectedCategory={currentCategory}
            />
          </div>
        );
      }

      get content() {
        const {
          isSearchBarVisible,
          channelData,
          isVideosFetching,
          videoIdsByPageNumber,
          searchQuery,
          currentVideosPageNumber,
          isSearching,
        } = this.props;

        if (!isVideosFetching && channelData.videosCount === 0) {
          return <ComingSoon coverUrl={getChannelCoverUrl(channelData)} />;
        }

        const classNames = classnames(styles.content, {
          [styles['no-search-bar']]: !isSearchBarVisible,
        });

        return (
          <div className={classNames}>
            <section
              className={styles['search-line']}
              aria-label={this.props.t('widget.accessibility.search-videos')}
              data-hook="widget-search-line"
            >
              {this.search}
              {this.categories}
            </section>
            {this.tags}

            <AccessibleVideosContainer
              channelTitle={channelData.title}
              isChannelHasVideos={Boolean(channelData.videosCount)}
              isVideosFetching={isVideosFetching}
              isSearching={isSearching}
              videoIdsByPageNumber={videoIdsByPageNumber}
              searchQuery={searchQuery}
              currentVideosPageNumber={currentVideosPageNumber}
              className={styles['videos-container']}
              dataHook="video-list"
              style={{ height: this.videosListHeight }}
              onRef={memoizedPartial(this.saveRef, 'videosContainerRef')}
            >
              {this.videosList}
              {this.noSearchResults}
            </AccessibleVideosContainer>
          </div>
        );
      }

      get channelTitle() {
        const { channelData, appSettings } = this.props;
        return appSettings.booleans.showChannelTitle ? channelData.title : null;
      }

      showChannelInfo = (event) => {
        event.preventDefault();
        this.props.openFullScreenChannelOverlay(this.props.channelData.id);
      };

      openMemberOnly = notForPreview((id) => {
        this.props.openFullScreenMemberOnlyOverlay(
          this.props.fedops,
          this.props.channelData.id,
          id,
        );
      });

      storeDataForReload = () => {
        this.props.storeForReload({
          selectedVideoId: this.props.mainVideoId,
        });
      };

      setCurrentVideoFromPayment = (paymentEvent) => {
        if (paymentEvent.itemId) {
          this.props.selectVideo(paymentEvent.itemId);
        }
      };

      get signInButton() {
        const { isSignInVisible, channelData } = this.props;

        if (!isSignInVisible) {
          return null;
        }

        return (
          <ChannelActionsContainer
            channelId={channelData.id}
            onPageRefresh={this.storeDataForReload}
          >
            {(channelActions) => (
              <AccountButton
                className={styles.action}
                tooltipSide="bottom"
                channelData={channelData}
                onClick={channelActions.showAccountInfo}
                onLoginClick={channelActions.logIn}
                onLogoutClick={channelActions.logOut}
                onSubscribeClick={channelActions.subscribe}
              />
            )}
          </ChannelActionsContainer>
        );
      }

      get header() {
        const { channelData, isHeaderVisible } = this.props;
        const classNames = classnames(styles.header, {
          [styles['empty-header']]: !isHeaderVisible,
        });

        const channelTitle = this.channelTitle;

        return (
          <header className={classNames} data-hook="widget-header">
            <h2
              className={styles['channel-name']}
              data-hook="channel-title"
              title={channelTitle}
            >
              {channelTitle}
            </h2>
            <OpenShareOverlay>
              {({ toggleShare, ariaLabel }) => (
                <ShareButton
                  className={styles.action}
                  ariaLabel={ariaLabel}
                  onClick={toggleShare}
                />
              )}
            </OpenShareOverlay>
            <ChannelInfoButton
              className={styles.action}
              channelData={channelData}
              onClick={this.showChannelInfo}
            />
            <ChannelSubscriptionButton
              className={styles.action}
              channelData={channelData}
              onClick={this.openSubscription}
            />
            {this.signInButton}
          </header>
        );
      }

      openSubscription = notForPreview(() => {
        const { currentChannelId } = this.props;
        this.props.logBi('widget.subscription.clicked', {
          channelID: currentChannelId,
        });
        this.props.openFullScreenSubscribeOverlay(
          this.props.fedops,
          currentChannelId,
          () => {
            this.props.logBi('widget.subscriptionPopUp.Completed', {
              result: 'canceled',
              errorMsg: '',
            });
          },
        );
      });

      openPurchase = notForPreview(() => {
        const { currentChannelId, mainVideoId } = this.props;
        this.props.openFullScreenPurchaseOverlay(
          this.props.fedops,
          currentChannelId,
          mainVideoId,
        );
      });

      openRent = notForPreview(() => {
        const { currentChannelId, mainVideoId } = this.props;
        this.props.openFullScreenRentOverlay(
          this.props.fedops,
          currentChannelId,
          mainVideoId,
        );
      });

      renderShareOverlay() {
        const { channelData, mainVideo } = this.props;
        const key = mainVideo ? mainVideo.id : channelData.id;
        return (
          <ShareOverlay
            key={`share-${key}`}
            channelData={channelData}
            videoItem={mainVideo}
          />
        );
      }

      renderMainSceneContent() {
        return (
          <div className={styles['main-scene-container']}>
            {this.renderPlayer()}
            {this.renderShareOverlay()}
          </div>
        );
      }

      renderPlayer() {
        const { mainVideo } = this.props;

        if (mainVideo) {
          return this.renderPlayerForCurrentDevice(mainVideo);
        }
      }

      handleOverlayPlayClick = (videoItem) => {
        const isMemberOnly =
          videoItem.memberOnly && !this.props.currentSiteUser;

        if (isMemberOnly) {
          this.openMemberOnly(videoItem.id);
        } else {
          this.playVideo(videoItem);
        }
      };

      playVideo = ({ id }, muted = false) => {
        const { requestPlayVideo, interactions, mainVideo } = this.props;

        startPlayInteraction(interactions, {
          layout: 'classic',
          source: mainVideo.videoSource,
          trigger: 'click',
        });
        requestPlayVideo(id, muted);
      };

      startAutoPlayFedopsInteraction = (videoPlayed) => {
        startPlayInteraction(this.props.interactions, {
          source: videoPlayed.videoSource,
          layout: 'compact',
          trigger: 'auto',
        });
      };

      playNextVideo = () => {
        const { nextVideoId: id, channelData, mainVideo } = this.props;
        if (id) {
          this.props.logWidgetVidClick({
            videoItem: mainVideo,
            channelData,
            buttonName: 'next_video',
          });

          this.playVideo({ id });
        }
      };

      handlePlayStarted = () => {
        const { interactions, mainVideo } = this.props;
        endPlayInteraction(interactions, {
          layout: 'classic',
          source: mainVideo.videoSource,
        });
      };

      renderPlayerForCurrentDevice(videoItem) {
        const {
          isVideoPlaying,
          isVideoEnded,
          canShowChannelCover,
          channelData,
          appSettings,
          nextVideoId,
          mainVideo,
          currentSiteUser,
          showAutoPlay,
          PlayerComponent,
          playerSize,
        } = this.props;

        const classicPlayerOverlay = (
          <ClassicPlayerOverlay
            appSettings={appSettings}
            currentSiteUser={currentSiteUser}
            channelData={channelData}
            videoItem={mainVideo}
            showChannelCover={canShowChannelCover}
            nextVideoId={nextVideoId}
            onPlaySelectedVideo={this.handleOverlayPlayClick}
            playNextVideo={this.playNextVideo}
            ended={isVideoEnded}
            saveRef={memoizedPartial(this.saveRef, 'playerOverlayContainerRef')}
          />
        );

        if (!PlayerComponent) {
          return (
            <div className={styles['player-container']}>
              <div className={styles.player} style={playerSize}>
                {classicPlayerOverlay}
              </div>
            </div>
          );
        }

        return (
          <div className={styles['player-container']}>
            <PlayerBlocksVisibility>
              {({ canShowVideoInfoButton, canShowShareButton }) => (
                <PlayerComponent
                  className={styles.player}
                  videoItem={videoItem}
                  channelData={channelData}
                  width={playerSize.width}
                  height={playerSize.height}
                  paused={!isVideoPlaying}
                  canShowChannelCover={canShowChannelCover}
                  onPurchaseClick={this.openPurchase}
                  onRentClick={this.openRent}
                  onSubscribeClick={this.openSubscription}
                  canShowFullInfo={canShowVideoInfoButton}
                  canShareVideo={canShowShareButton}
                  onPlayStart={this.handlePlayStarted}
                >
                  {classicPlayerOverlay}
                </PlayerComponent>
              )}
            </PlayerBlocksVisibility>
            <DeeplinkPopups />
            {showAutoPlay && (
              <AutoPlayVideo
                dataHook="autoplay-video"
                onAutoPlayRequested={this.startAutoPlayFedopsInteraction}
              />
            )}
          </div>
        );
      }

      saveContainerRef = (ref) => {
        this.containerRef = ref;
      };

      render() {
        const {
          channelData,
          isVideoPlaying,
          mainVideoId,
          windowSize,
          isEditor,
        } = this.props;

        const style = isEditor
          ? {
              width: '100%',
              height: '100%',
            }
          : {
              width: windowSize.width,
              minHeight: windowSize.height,
            };

        return (
          <main
            ref={this.saveContainerRef}
            className={styles.container}
            style={style}
            data-hook="widget-container"
            data-channel-layout="classic"
            aria-label={this.props.t(
              'widget.accessibility.channel-videos-widget',
              {
                channelTitle: channelData.title,
              },
            )}
            tabIndex={0}
          >
            {this.header}
            {this.renderMainSceneContent()}
            {this.content}

            <PaymentEvents
              onRent={this.setCurrentVideoFromPayment}
              onSale={this.setCurrentVideoFromPayment}
              onSubscription={this.reset}
              onSubscriptionCancel={this.reset}
            />

            <LiveStartHandler
              playVideo={this.playVideo}
              isVideoPlaying={isVideoPlaying}
              selectedVideoId={mainVideoId}
            />

            <WidgetPerformanceLoggers />
          </main>
        );
      }
    },
  ),
);

export const ClassicView = withFedopsInteractions(
  withWindowSize(ClassicViewComponent),
);
export default withPlayerModuleLoader(ClassicView);
